import { useEffect, useState } from 'react'
import {
  arrayOf,
  bool,
  func,
  object,
  objectOf,
  shape,
  string,
} from 'prop-types'
import { faLock, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Axios from 'axios'

import Tooltip from '../tooltip/Tooltip'
import IsReadOnlyPolicy from '../../utils/IsReadOnlyPolicy'
import Modal from '../Modal/Modal'
import ModalActionButtons from '../Modal/ModalActionButtons'
import ConfirmationPopupSkeleton from '../LoadingSkeletons/ConfirmationPopupSkeleton'
import {
  isExchangePolicy,
  isPolicyTypeCanBeDisabled,
  isTenantLevelSettingPolicyType,
} from '../../utils/Policy'

const PolicyViewerChildren = ({
  nestedChildPolicies,
  isUnlicensed,
  selectedTargetTenant,
  handleChildCheckboxChange,
  setTogglePolicyNotification,
  checkedItems,
  parentType,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [policyChildData, setPolicyChildData] = useState()
  const [checkboxStates, setCheckboxStates] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  // Set checkboxes from list policies state and toggle
  useEffect(() => {
    const initialCheckboxStates = {}
    nestedChildPolicies.forEach(child => {
      initialCheckboxStates[child.id] =
        child.state === 'enabled' || child.state === 'Enabled'
    })

    setCheckboxStates(initialCheckboxStates)
  }, [nestedChildPolicies])

  // View Policy popup function
  const handleViewClick = async (policyId, parentName, childName) => {
    const fePolicy = {
      clientTenantId: +selectedTargetTenant.clientTenantId,
      policyTypeId: +policyId.PolicyTypeId,
      policyGuid: policyId.id,
      target: '365',
    }

    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-policy-data`,
        { params: fePolicy }
      )

      const policyData = Array.isArray(response.data)
        ? response.data[0]
        : response.data

      window.policyData = JSON.stringify(policyData)
      window.policyTypeName = JSON.stringify(parentName)
      window.policyDisplayName = JSON.stringify(childName)
      window.tenantName = JSON.stringify(
        selectedTargetTenant.tenantFriendlyName
      )

      window.open(
        '/policy.html',
        `view-policy-${policyData.id}`,
        'width=600,height=900'
      )
    } catch (getPolicyError) {
      console.error(getPolicyError)
    }
  }

  // Toggle Exchange policy
  const togglePolicyDialog = async child => {
    setPolicyChildData(child)
    setModalOpen(true)
  }

  const togglePolicy = async () => {
    setLoading(true)
    setTogglePolicyNotification(true)

    const requestBody = {
      clientTenantId: +selectedTargetTenant.clientTenantId,
      policyGuid: policyChildData.id,
      policyTypeId: policyChildData.PolicyTypeId,
      displayName: policyChildData.displayName || policyChildData.name,
      enable: !checkboxStates[policyChildData.id],
    }

    const route = isExchangePolicy(policyChildData.PolicyTypeId)
      ? 'toggle-policy'
      : 'toggle-ca-policy'

    try {
      await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/${route}`,
        requestBody
      )

      setCheckboxStates(prevStates => ({
        ...prevStates,
        [policyChildData.id]: !prevStates[policyChildData.id], // This toggles the current state
      }))

      setModalOpen(false)
    } catch (togglePolicyError) {
      console.error(togglePolicyError)

      setError('Could not toggle state, please try again.')
    } finally {
      setLoading(false)
    }
  }

  const { isSharedBaseline } = selectedTargetTenant

  return (
    <>
      <ul
        className={`checkbox-children ${isUnlicensed ? 'pointer-events-none opacity-60' : ''}`}
      >
        {Array.isArray(nestedChildPolicies) &&
        nestedChildPolicies.length > 0 ? (
          nestedChildPolicies.map(child => {
            const isReadOnly = IsReadOnlyPolicy(child)

            return (
              <li key={child.id}>
                {!isTenantLevelSettingPolicyType(child.PolicyTypeId) &&
                  (isReadOnly ? (
                    <span className='lock' aria-label='Padlock'>
                      <Tooltip content='Read Only'>
                        <FontAwesomeIcon icon={faLock} />
                      </Tooltip>
                    </span>
                  ) : !isSharedBaseline ? (
                    <input
                      aria-label='Child policy selected toggle'
                      id={`selectedChildPolicy-${child.id}`}
                      type='checkbox'
                      disabled={isUnlicensed || isReadOnly}
                      name={child.id.toString()}
                      checked={checkedItems[child.id] || false}
                      onChange={handleChildCheckboxChange}
                    />
                  ) : null)}
                <span className={`${isReadOnly ? 'opacity-70 pr-3' : ''}`}>
                  {child.displayName || child.name}
                </span>
                {!isUnlicensed && (
                  <div className='policy-explorer-btns flex items-center'>
                    {isPolicyTypeCanBeDisabled(child.PolicyTypeId) &&
                      !isSharedBaseline && (
                        <div
                          className={`flex items-center ${isReadOnly && 'opacity-70'}`}
                        >
                          <label
                            htmlFor='policyToggle'
                            className='mr-[10px] text-sm'
                          >
                            {checkboxStates[child.id] ? 'Enabled' : 'Disabled'}
                          </label>
                          <input
                            id='policyToggle'
                            type='checkbox'
                            checked={checkboxStates[child.id] || false}
                            onChange={() => togglePolicyDialog(child)}
                            disabled={isReadOnly}
                          />
                        </div>
                      )}
                    <button
                      type='button'
                      aria-label='View Policy JSON'
                      className='btn'
                      onClick={() =>
                        handleViewClick(
                          child,
                          parentType,
                          child.displayName || child.name
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </button>
                  </div>
                )}
              </li>
            )
          })
        ) : (
          <li>No children found.</li>
        )}
      </ul>
      <Modal isOpen={isModalOpen} setModalOpen={setModalOpen}>
        {loading ? (
          <ConfirmationPopupSkeleton />
        ) : (
          <>
            {error && <p className='text-red-500'>{error}</p>}
            <h4>
              {`${checkboxStates[policyChildData?.id] ? 'Disable' : 'Enable'} policy`}
            </h4>
            <p className='pt-2'>
              Are you sure you want to{' '}
              {`${checkboxStates[policyChildData?.id] ? 'disable' : 'enable'} this policy?`}
            </p>
            <ModalActionButtons
              onCancel={() => {
                setModalOpen(false)
                setError(false)
              }}
              onConfirm={() => {
                togglePolicy()
              }}
              cancelActionText='Cancel'
              confirmActionText='Confirm'
            />
          </>
        )}
      </Modal>
    </>
  )
}

PolicyViewerChildren.defaultProps = {
  isUnlicensed: false,
}

PolicyViewerChildren.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  nestedChildPolicies: arrayOf(object).isRequired,
  isUnlicensed: bool,
  selectedTargetTenant: shape({
    clientTenantId: string.isRequired,
    tenantFriendlyName: string.isRequired,
  }).isRequired,
  handleChildCheckboxChange: func.isRequired,
  setTogglePolicyNotification: func.isRequired,
  checkedItems: objectOf(bool).isRequired,
  parentType: string.isRequired,
}
export default PolicyViewerChildren
