import TagsCell from '../ListTables/TagsCell'

const TenantTagsCell = ({ text, colour }) => {
  const tags = []
  tags.push({
    text,
    colour,
    styles: 'tenant-tag',
  })

  return <TagsCell tags={tags} />
}

export default TenantTagsCell
