import { string } from 'prop-types'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import Alerts from '../alerts/Alerts'

import Modal from '../Modal/Modal'
import ModalActionButtons from '../Modal/ModalActionButtons'
import ConfirmationPopupSkeleton from '../LoadingSkeletons/ConfirmationPopupSkeleton'

import useDeleteTenantTag from './api/useDeleteTenantTag'

const TenantTagsActions = ({ id, tag }) => {
  const [isOpen, setIsOpen] = useState(false)

  const deleteTenantTag = useDeleteTenantTag(() => {
    setIsOpen(false)
    toast.success(`${tag} deleted successfully`)
  })

  return (
    <>
      <FontAwesomeIcon
        icon={faEdit}
        className='mx-1 hover:cursor-pointer'
        aria-label='edit'
      />

      <FontAwesomeIcon
        onClick={() => setIsOpen(true)}
        icon={faTrash}
        className='mx-1 hover:cursor-pointer'
        aria-label='trash'
      />

      <Modal isOpen={isOpen} setModalOpen={setIsOpen}>
        {deleteTenantTag.isPending ? (
          <ConfirmationPopupSkeleton />
        ) : (
          <>
            <h2 className='text-xl font-bold mb-4'>
              Are you sure you want to delete "{tag}" tag?
            </h2>

            {deleteTenantTag.isError && (
              <Alerts
                alert='error'
                title={deleteTenantTag?.error?.response?.data.message}
              >
                <ul>
                  {deleteTenantTag.error?.response?.data?.errors.map(error => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </Alerts>
            )}

            <ModalActionButtons
              onCancel={() => {
                setIsOpen(false)
              }}
              onConfirm={() => {
                deleteTenantTag.mutate(id)
              }}
              cancelActionText='Cancel'
              confirmActionText='Confirm'
            />
          </>
        )}
      </Modal>
    </>
  )
}

export default TenantTagsActions

TenantTagsActions.propTypes = {
  id: string.isRequired,
  tag: string.isRequired,
}
