import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

export const useCreateTag = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: newTag =>
      axios.post(`${process.env.REACT_APP_MIDDLEWARE_URL}/tenantTags`, newTag),
    onSuccess: () => {
      toast.success('Tag created successfully!')
      queryClient.invalidateQueries(['tenantTagsList'])
    },
    onError: error => {
      const errorMessage =
        error.response?.data?.message ||
        'Something went wrong. Please try again.'
      toast.error(errorMessage)
    },
  })
}
