import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { HexColorPicker } from 'react-colorful'
import { useCreateTag } from './api/useCreateTenantTag'
import Alerts from '../alerts/Alerts'
import './TenantTags.css'

const CreateNewTagForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const createTagMutation = useCreateTag()
  const [colour, setColour] = useState('#E7F3FB')

  const onSubmit = data => {
    const newTag = { ...data, colour }
    createTagMutation.mutate(newTag, {
      onSuccess: () => {
        reset()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Alerts title='Tips:' alert='info-cyan' margin='mb-3'>
        <ul className='list-disc'>
          <li>
            Press <code>Win + .</code> to add an emoji to your tag name
          </li>
          <li>Use a light background colour so the tag text is readable</li>
        </ul>
      </Alerts>
      <div className='flex flex-col'>
        {errors.tag && <p className='text-red-500'>{errors.tag.message}</p>}
        <input
          type='text'
          id='tag'
          {...register('tag', { required: 'Tag name required' })}
          placeholder='Enter Tag Name'
          className={`flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400 ${errors.tag ? 'border-red-500' : 'focus:outline-blue-400'}`}
        />
      </div>

      <div className='flex flex-col'>
        <textarea
          id='description'
          {...register('description', { required: false })}
          placeholder='Enter a description'
          className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400'
        />
      </div>
      <div className='flex flex-col mt-1'>
        <label htmlFor='color'>Pick a tag background color</label>
        <div className='mt-2 create-tag-picker'>
          <HexColorPicker
            color={colour}
            onChange={setColour}
            className='w-full'
          />
        </div>
      </div>

      <button type='submit' className='btn cyan-btn mt-6'>
        Submit
      </button>
    </form>
  )
}

export default CreateNewTagForm
