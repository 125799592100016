import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import TenantTagsCell from './TenantTagsCell'
import ListTable from '../ListTables/ListTable'
import TenantTagsActions from './TenantTagsActions'
import useTenantTags from './api/useTenantTags'
import ContentPanel from './ContentPanel'
import CreateNewTagForm from './CreateNewTagForm'
import Alerts from '../alerts/Alerts'

const TenantTagSettings = () => {
  const [tenantTags, setTenantTags] = useState([])
  const [isOpen, setIsOpen] = useState(false)

  const {
    data: tenantTagsList,
    isLoading: isTenantTagsLoading,
    isError: isTenantTagsError,
    error: tenantTagsError,
  } = useTenantTags()

  const togglePanel = () => setIsOpen(!isOpen)

  const ListTenantTagsConfig = {
    columns: [
      {
        header: 'Tag',
        accessor: 'tag',
        cell: ({ row }) =>
          TenantTagsCell({
            text: row.original.tag,
            colour: row.original.colour,
            textColor: row.original.textColor,
          }),
      },
      {
        header: 'Description',
        accessor: 'description',
      },
      {
        header: 'Tenant count',
        cell: ({ row }) => row.original.tenants.length,
      },
      {
        header: 'Actions',
        accessor: 'actions',
        cell: ({ row }) =>
          TenantTagsActions({ id: row.original.id, tag: row.original.tag }),
      },
    ],
    data: tenantTagsList?.data,
    positionGlobalFilter: 'right',
    renderTopToolbarCustomActions: () => (
      <button type='button' className='btn cyan-btn' onClick={togglePanel}>
        <FontAwesomeIcon icon={faPlus} className='mr-1' /> Create Tag
      </button>
    ),
  }

  const errorMessage =
    tenantTagsError?.response?.data?.message || 'Something went wrong'
  const errorDetails = tenantTagsError?.response?.data?.errors?.join(', ') || ''

  return (
    <div>
      {!isTenantTagsLoading && (
        <ListTable
          selectedListItems={tenantTags}
          setSelectedListItems={setTenantTags}
          uniqueKey='id'
          config={ListTenantTagsConfig}
          enableRowSelection={false}
          enableMultiRowSelection={false}
        />
      )}
      {isTenantTagsError && (
        <Alerts type='error' title={errorMessage} message={errorDetails} />
      )}
      <ContentPanel
        isOpen={isOpen}
        togglePanel={togglePanel}
        panelHeading='Create a Tag'
      >
        <CreateNewTagForm togglePanel={togglePanel} />
      </ContentPanel>
    </div>
  )
}

export default TenantTagSettings
